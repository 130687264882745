import handleMobileMenu from "./../_mobileMenu";

export default {

    init() {
        handleMobileMenu();
        const blogs = document.querySelectorAll('.slanted-post');
        blogs.forEach(blog => {
            blog.addEventListener('mouseenter', () => {
                blog.classList.add('hovering')
            })
            blog.addEventListener('mouseleave', () => {
                blog.classList.remove('hovering')
            })
        })

    },

    finalize() {



    },

}